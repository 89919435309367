
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import { typeList } from './formwork.model';
import { ParamString, ParamNumber, ParamBoolean, ParamObject, ParamArray } from './params/param.model';
@Options({
  components: {
    'a-select': Select,
    'a-select-option': Select.Option,
  },
  props: {
    formwork: {
      type: Array,
      default: [],
    },
    asParam: Boolean,
  },
  emits: {
    'update:formwork': Array,
  },
})
export default class Formwork extends Vue {
  public formwork!: any[];
  public asParam = false;
  set params(value) {
    this.formwork = value;
  }
  get params() {
    return this.formwork;
  }
  public readonly typeList = typeList;
  public add() {
    this.params.push(new ParamString());
    this.optionChange();
  }
  public deleteParam(index: number) {
    this.params.splice(index, 1);
  }
  public changeType(type: string, index: number) {
    console.log(type);
    this.params[index] = this.newParam(type);
    this.$forceUpdate();
  }
  public newParam(type: string = 'string') {
    switch (type) {
      case 'string': return new ParamString();
      case 'number': return new ParamNumber();
      case 'boolean': return new ParamBoolean();
      case 'array': return new ParamArray();
      case 'object': return new ParamObject();
      default: break;
    }
  }
  public optionChange() {
    this.$emit('update:formwork', this.params);
  }
  public say() {
    console.log('formwork say');
  }

}
