<template>
  <div>
    <div class="formwork-content">
      <div class="item width-fit" v-for="(param, index) in params" :key="index">
        <div class="flex">
          <div class="flex1 flex">
            <label>参数类型：</label>
              <a-select :value='param.type' @change="changeType($event, index)" class="flex1">
                <a-select-option
                  v-for="type in typeList"
                  :key="type"
                  :value="type">{{type}}
                </a-select-option>
              </a-select>
          </div>
          <div class="flex1 flexv-center">
            <button class="delete_param" @click="deleteParam(index)">删除</button>
          </div>
        </div>
        <div v-if="params[index].type === 'string'">
          <StringParam
            :asParam="asParam"
            :option="params[index]"
            @optionChange="
              params[index] = $event;
              optionChange;
            "
          />
        </div>
        <div v-else-if="params[index].type === 'number'">
          <NumberParam
            :asParam="asParam"
            :option="params[index]"
            @optionChange="
              params[index] = $event;
              optionChange;
            "
          />
        </div>
        <div v-else-if="params[index].type === 'boolean'">
          <BooleanParam
            :asParam="asParam"
            :option="params[index]"
            @optionChange="
              params[index] = $event;
              optionChange;
            "
          />
        </div>
        <div v-else-if="params[index].type === 'object'">
          <ObjectParam
            :asParam="asParam"
            :option="params[index]"
            @optionChange="
              params[index] = $event;
              optionChange;
            "
          />
        </div>
        <div v-else-if="params[index].type === 'array'">
          <ArrayParam
            :asParam="asParam"
            :option="params[index]"
            @optionChange="
              params[index] = $event;
              optionChange;
            "
          />
        </div>
      </div>
    </div>
    <button class="blue-button" @click="add()">添加参数</button>
  </div>
</template>

<script lang="ts">
import { Select } from 'ant-design-vue';
import { Options, Vue } from 'vue-class-component';
import { typeList } from './formwork.model';
import { ParamString, ParamNumber, ParamBoolean, ParamObject, ParamArray } from './params/param.model';
@Options({
  components: {
    'a-select': Select,
    'a-select-option': Select.Option,
  },
  props: {
    formwork: {
      type: Array,
      default: [],
    },
    asParam: Boolean,
  },
  emits: {
    'update:formwork': Array,
  },
})
export default class Formwork extends Vue {
  public formwork!: any[];
  public asParam = false;
  set params(value) {
    this.formwork = value;
  }
  get params() {
    return this.formwork;
  }
  public readonly typeList = typeList;
  public add() {
    this.params.push(new ParamString());
    this.optionChange();
  }
  public deleteParam(index: number) {
    this.params.splice(index, 1);
  }
  public changeType(type: string, index: number) {
    console.log(type);
    this.params[index] = this.newParam(type);
    this.$forceUpdate();
  }
  public newParam(type: string = 'string') {
    switch (type) {
      case 'string': return new ParamString();
      case 'number': return new ParamNumber();
      case 'boolean': return new ParamBoolean();
      case 'array': return new ParamArray();
      case 'object': return new ParamObject();
      default: break;
    }
  }
  public optionChange() {
    this.$emit('update:formwork', this.params);
  }
  public say() {
    console.log('formwork say');
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.formwork-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  padding: 10px;
  width: 100%;
  min-width: fit-content;
}
label {
  line-height: 32px;
}
.blue-button{
  margin: 14px
}
.add_param{
  font-size: 14px;
  color: #1890FF;
  text-decoration: underline;
}
.delete_param{
  color: #FF4949;
  font-size: 14px;
  text-decoration: underline;
}
</style>
